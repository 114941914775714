import { Login as LoginForm, LogoHome, Seo } from "components"
import Layout from "layout"
import React from "react"

const Login = () => (
  <Layout>
    <Seo title="Login" />
    <section id="login" className="d-flex justify-content-around">
      <LoginForm />
      <LogoHome />
    </section>
  </Layout>
)

export default Login
